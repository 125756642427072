import React from "react";
import { Container, ParicipantTextDetailDiv } from "./elements";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { H2BigStrong, H4 } from "../Typography";
import Img from "gatsby-image";

const ParticipantDetailHero = ({ showCommunityMember, title, headerImages }) => {
  return (
    <Container>
      <AnimationOnScroll
        animatePreScroll={true}
        animateIn="animate__zoomInDown"
        // animateOut="animate__zoomOutUp"
        animateOnce
        duration={1}
        offset={50}
      >
        <ParicipantTextDetailDiv>
          {showCommunityMember ? <H4>Community Member</H4> : null}
          <H2BigStrong>{title}</H2BigStrong>
          {headerImages?.length ? (
            <Img fixed={headerImages?.[0]?.localFile?.childImageSharp?.fixed} style={{ width: "100%", maxWidth: "820px", maxHeight: "540px" }} />
          ) : null}
        </ParicipantTextDetailDiv>
      </AnimationOnScroll>
    </Container>
  );
};

export default ParticipantDetailHero;
