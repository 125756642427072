import Layout from "../components/Layout";
import React from "react";
import { graphql } from "gatsby";
import { ParticipantHeaderDiv } from "../styles/participants";
import ParticipantDetailHero from "../components/ParticipantDetailHero";
import ParticipantDetailBody from "../components/ParticipantDetailBody";
import EventListGrid from "../components/EventListGrid";
import ImagesSwiper from "../components/ImagesSwiper";

const ParticipantPage = ({ data, pageContext }) => {
  const {
    isCommunityMember,
    title,
    headerImages,
    communityMemberDescription,
    richTextField,
    communityMemberContactDetails,
    qafParticipantType,
    communityMemberEmailAddress,
    communityMemberWebsite,
    communityMemberOpeningHours,
    participantLocation,
    communityMemberImages,
  } = data?.craftEntryInterface;

  const now = new Date();
  const eventList = data?.allEvents?.nodes?.filter((event) => {
    const locationList = event?.qafEventLocation?.map((location) => location?.title);
    const thisDate = new Date(event.eventEndDateTime || event.eventStartDateTime || null);
    return locationList?.includes(title) && thisDate >= now;
  });

  const eventTags = eventList?.reduce((acc, event) => {
    const tags = event?.qafEventType?.map((type) => type?.id);
    return [...acc, ...tags];
  }, []);

  const relatedEvents = data?.allEvents?.nodes
    ?.sort(() => 0.5 - Math.random())
    ?.filter((event) => {
      const thisDate = new Date(event.eventEndDateTime || event.eventStartDateTime || null);
      return thisDate >= now && event?.qafEventType?.some((type) => eventTags?.includes(type?.id)) && !eventList?.includes(event);
    })
    ?.slice(0, 3);

  return (
    <Layout backgroundColor={"var(--bg-color)"} skipHeader headerBlack={true} title={title}>
      <ParticipantHeaderDiv>
        <ParticipantDetailHero showCommunityMember={isCommunityMember} title={title} headerImages={headerImages} />
      </ParticipantHeaderDiv>
      <ParticipantDetailBody
        isCommunityMember={isCommunityMember}
        description={communityMemberDescription}
        subDescription={richTextField}
        contactDetails={communityMemberContactDetails}
        participantType={qafParticipantType}
        communityMemberEmailAddress={communityMemberEmailAddress}
        communityMemberWebsite={communityMemberWebsite}
        communityMemberOpeningHours={communityMemberOpeningHours}
        participantLocation={participantLocation}
      />
      {eventList?.length ? <EventListGrid title={"Event"} events={eventList} itemsPerLine={3} hideArrow /> : null}

      {communityMemberImages?.length ? <ImagesSwiper images={communityMemberImages} /> : null}

      {relatedEvents?.length ? <EventListGrid title={"Related Events"} events={relatedEvents} itemsPerLine={3} hideArrow /> : null}
    </Layout>
  );
};

export default ParticipantPage;

export const pageQuery = graphql`
  query ($id: String!) {
    craftEntryInterface(id: { eq: $id }) {
      ... on Craft_participants_default_Entry {
        id
        isCommunityMember
        title
        communityMemberOpeningHours
        participantLocation {
          title
        }
        headerImages: qafParticipantHeaderImage {
          ... on Craft_communityAssets_Asset {
            id
            localFile {
              childImageSharp {
                fixed(width: 820, quality: 100) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
          ... on Craft_eventsAssets_Asset {
            id
            localFile {
              childImageSharp {
                fixed(width: 820, quality: 100) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
        communityMemberDescription
        richTextField
        communityMemberImages {
          ... on Craft_communityAssets_Asset {
            id
            localFile {
              childImageSharp {
                fixed(height: 365, quality: 100) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
          ... on Craft_eventsAssets_Asset {
            id
            localFile {
              childImageSharp {
                fixed(height: 365, quality: 100) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
        qafParticipantType {
          ... on Craft_qafParticipantType_default_Entry {
            id
            title
          }
        }
        communityMemberEmailAddress
        communityMemberWebsite
        communityMemberContactDetails {
          method
          value
        }
      }
    }
    allEvents: allCraftQafEventsQafEventEntry {
      nodes {
        id
        title
        isStaging
        eventEndDateTime
        eventStartDateTime
        eventShortDescription
        qafEventLocation {
          title
          ... on Craft_participants_default_Entry {
            participantLocation {
              title
            }
            communityMemberOpeningHours
            communityMemberSlug
            communityMemberWebsite
            communityMemberEmailAddress
            communityMemberDescription
            communityMemberContactDetails {
              col1
              col2
            }
            qafParticipantType {
              ... on Craft_qafParticipantType_default_Entry {
                id
                title
              }
            }
          }
          ... on Craft_maplocation_default_Entry {
            locationId
            locationName
            title
            allowClick
          }
        }
        slug
        qafEventType {
          ... on Craft_qafEventType_default_Entry {
            id
            title
          }
        }
        headerImage: qafEventHeaderImage {
          ... on Craft_eventsAssets_Asset {
            cardImage: localFile {
              publicURL
              childImageSharp {
                fixed(fit: COVER, height: 295, quality: 100) {
                  src
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  }
`;
