import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 25px;
  > div {
    flex: 1;
  }
`;

export const ParicipantTextDetailDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  background-color: var(--bg-color);
  > h4 {
    color: var(--text-color);
    margin-bottom: 15px;
  }
  > h2 {
    color: var(--primary);
    margin-bottom: 35px;
  }
  @media (max-width: 767px) {
    padding: 0 20px 30px;
    > .gatsby-image-wrapper {
      max-height: 295px !important;
    }
    > h4 {
      font-size: 18px;
      font-weight: 500;
    }
    > h2 {
      font-size: 36px;
      line-height: 1.05;
    }
  }
`;
