import React from "react";
import { Container, LeftContent, ParicipantTextDetailDiv, InfoBlock, RightContent, ParticipantPageButton } from "./elements";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { H3SmallMedium, H2BigStrong, H4, H6Medium, H5SmallStrong, H5 } from "../Typography";
import Img from "gatsby-image";
import MiscSocialSmallParticipant from "../icons/MiscSocialSmallParticipant";
import { AnimatedButton } from "../Buttons/elements";
import { navigate } from "gatsby";

const ParticipantDetailBody = ({
  isCommunityMember,
  description,
  subDescription,
  contactDetails,
  participantType,
  communityMemberEmailAddress,
  communityMemberWebsite,
  communityMemberOpeningHours,
  participantLocation,
}) => {
  const socialMethods = ["linkedin", "twitter", "facebook", "instagram"];
  const participantLinks = {
    linkedin: contactDetails?.find((item) => item?.method?.toLowerCase() === "linkedin")?.value,
    twitter: contactDetails?.find((item) => item?.method?.toLowerCase() === "twitter")?.value,
    facebook: contactDetails?.find((item) => item?.method?.toLowerCase() === "facebook")?.value,
    instagram: contactDetails?.find((item) => item?.method?.toLowerCase() === "instagram")?.value,
    website: communityMemberWebsite,
  };

  const participantLinksOther = contactDetails?.filter((item) => !socialMethods.includes(item?.method?.toLowerCase()) && !!item?.value);

  return (
    <Container>
      <LeftContent>
        {description ? (
          <H3SmallMedium
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          ></H3SmallMedium>
        ) : null}
        {subDescription ? (
          <H3SmallMedium
            dangerouslySetInnerHTML={{
              __html: subDescription,
            }}
          ></H3SmallMedium>
        ) : null}
      </LeftContent>
      <RightContent>
        {contactDetails?.length ? <MiscSocialSmallParticipant participantLinks={participantLinks} /> : null}
        {participantType?.length ? (
          <InfoBlock>
            <H5SmallStrong>TYPE</H5SmallStrong>
            <H5>
              {participantType?.map((type, typeIndex) => {
                return `${type?.title}${typeIndex === participantType?.length - 1 ? "" : ", "}`;
              })}
            </H5>
          </InfoBlock>
        ) : null}

        {/* {communityMemberOpeningHours ? (
          <InfoBlock>
            <H5SmallStrong>HOURS</H5SmallStrong>
            <H5>{communityMemberOpeningHours}</H5>
          </InfoBlock>
        ) : null} */}

        {communityMemberEmailAddress || participantLinksOther?.length ? (
          <InfoBlock>
            <H5SmallStrong>CONTACT DETAILS</H5SmallStrong>
            {participantLinksOther?.length ? (
              <>
                {participantLinksOther?.map((link, linkIndex) => {
                  return link?.method === "Phone" || link?.method === "Whatsapp" ? (
                    <a href={`tel:${link?.value}`} key={linkIndex}>
                      <H5>
                        {link?.method} {link?.value}
                      </H5>
                    </a>
                  ) : (
                    <H5 key={linkIndex}>
                      {link?.method} {link?.value}
                    </H5>
                  );
                })}
              </>
            ) : null}
            {communityMemberEmailAddress ? (
              <a href={`mailto:${communityMemberEmailAddress}`}>
                <H5>{communityMemberEmailAddress}</H5>
              </a>
            ) : null}
          </InfoBlock>
        ) : null}
        {isCommunityMember && participantLocation?.length ? (
          <AnimatedButton className="animatedButton">
            <ParticipantPageButton
              onClick={() => {
                navigate(`/map?location=${encodeURI(participantLocation?.[0]?.title || "")}`);
              }}
            >
              View on map
            </ParticipantPageButton>
          </AnimatedButton>
        ) : null}
      </RightContent>
    </Container>
  );
};

export default ParticipantDetailBody;
