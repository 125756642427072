import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 820px;
  width: calc(100% - 40px);
  margin: 50px auto;
  color: var(--text-color);

  @media (max-width: 767px) {
    width: unset;
    flex-direction: column;
    margin: 0px 20px 30px 20px;
    gap: 30px;
  }
`;

export const LeftContent = styled.div`
  width: 505px;
  > h3:first-child > p:first-child {
    margin: 0;
  }
  h3:last-child > p:last-child {
    margin-bottom: 0;
  }
  strong {
    color: var(--primary);
  }
  @media (max-width: 767px) {
    width: 100%;
    p {
      font-size: 21px;
    }
  }
  figure {
    margin: 0;
  }
  img {
    width: 100%;
  }
`;

export const RightContent = styled.div`
  width: 210px;
  display: flex;
  flex-direction: column;
  gap: 18px;
  h5 {
    line-height: 1.6;
  }
  .animatedButton {
    width: fit-content;
  }
`;

export const InfoBlock = styled.div`
  border-top: 2px solid var(--text-color);
  padding-top: 8px;
  a {
    color: var(--text-color);
    &:hover {
      color: var(--primary);
    }
  }
`;

export const ParticipantPageButton = styled.button`
  font-family: Euclid Circular A;
  color: var(--btn-text);
  background: var(--btn-bg);
  border: none;
  padding: 5px 20px;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.06em;
  line-height: 150%;
  transition: all 0.3s;
  cursor: var(--cursor-hover) 0 0, pointer;
  &.transparent {
    color: var(--white);
    background: transparent;
    border: 2px solid var(--primary);
  }
`;
